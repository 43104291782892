import { cloneDeep, isEmpty, isEqual } from 'lodash'

import { SolutionAnalysisReducerService } from './SolutionAnalysisReducerService'
import { SolutionAnalysisService } from './SolutionAnalysisService'
import { Action } from 'global actions/ActionModels'
import {
	AM_MATERIAL_CATEGORY_CHANGED,
	AM_MATERIAL_CHANGED,
	AM_MATERIAL_SELECTOR_TOGGLED,
	AM_MATERIAL_TYPE_CHANGED,
	AM_SUB_CATEGORY_CHANGED,
	CHAIN_BENEFITS_FORM_EDIT_CANCELLED,
	CLOSE_PART_REDUCE_WEIGHT_PROGRESS_POPUP_SOLUTION_ANALYSIS,
	FEA_ANALYSIS_ALERT_CANCELED,
	FEA_ANALYSIS_ALERT_LOADER_TOGGLED,
	FEA_ANALYSIS_DATA_FETCHED,
	FEA_ANALYSIS_DATA_RESET,
	FEA_NEW_ANALYSIS_CLICKED,
	FEA_NEW_ANALYSIS_TOGGLED,
	FEA_OLD_ANALYSIS_TOGGLED,
	LIFE_CYCLE_CHANGED,
	LIFE_CYCLE_FORM_SUBMITED,
	MATERIAL_CATEGORY_CHANGED,
	MATERIAL_CHANGED,
	MATERIAL_SELECTOR_TOGGLED,
	MATERIAL_TYPE_CHANGED,
	OPEN_PART_REDUCE_WEIGHT_PROGRESS_POPUP_SOLUTION_ANALYSIS,
	SIMPLE_CONFIGURATION_INHOUSE_TOGGLED,
	SIMPLE_CONFIGURATION_SELECTOR_COMPANY_CHANGED,
	SIMPLE_CONFIGURATION_SELECTOR_MATERIAL_CHANGED,
	SIMPLE_CONFIGURATION_SELECTOR_PRINTER_CHANGED
} from 'global actions/types'
import {
	ALTERNATIVE_SOLUTION_CHANGED,
	ASSEMBLING_PARAMS_UPDATED,
	BATCH_SIZE_CHANGED,
	BEFORE_SEND_PART_TO_CONSUMER,
	CHAIN_BENEFITS_CHECK_BOX_CHANGED,
	CHAIN_BENEFITS_UPDATE_FINISHED,
	CHAIN_BENEFITS_UPDATE_STARTED,
	CHAIN_BENEFITS_YEARS_OF_DEMAND_CHANGED,
	CHANGE_ORGANIZATION_TO_UPDATE_CONFIGURATION,
	CONFIGURATION_ANALYSIS_3D_MODAL_CLOSED,
	CONFIGURATION_ANALYSIS_3D_MODAL_OPENED,
	CONFIGURATION_INHOUSE_ALERT_WITH_MATERIAL,
	CONFIGURATION_IS_SET_LEADING,
	CONFIGURATION_TOLERANCES_LOADING_TOGGLED,
	CONFIGURATION_WALL_THICKNESS_CANCELLED,
	CONFIGURATION_WALL_THICKNESS_CLICKED,
	CONFIGURATION_WALL_THICKNESS_LOADING_TOGGLED,
	CONFIGURATION_WALL_THICKNESS_LOADING_UPDATED,
	COST_COMPARISON_EDIT_CLICKED,
	COST_COMPARISON_EDIT_FINISHED,
	COST_COMPARISON_EDIT_STARTED,
	COST_COMPARISON_FORM_CANCELLED,
	COST_COMPARISON_QUANTITY_CHANGED,
	COST_COMPARISON_RESET_CHAIN_BENEFITS,
	END_POLLING_TRAY_ORIENTATION_DATA,
	EXPORT_3D_LINK_UPDATED,
	FINISHED_FETCHING_CONFIGURATION_ORGANIZATION_SETTINGS,
	GEOMETRY_ANALYSIS_ALERT_CANCELLED,
	GEOMETRY_ANALYSIS_ALERT_OPENED,
	GEOMETRY_ANALYSIS_PRINT_ISSUE_LOAD_TOGGLED,
	GLOBAL_CHAIN_BENEFITS_CHECK_BOX_CHANGED,
	LEAD_TIME_EDIT_FINISHED,
	LEAD_TIME_EDIT_STARTED,
	LEAD_TIME_FORM_EDIT_CLICKED,
	LEAD_TIME_FORM_FORM_CANCELLED,
	LEAD_TIME_GRAPH_UNLOCKED_ERROR,
	LEAD_TIME_GRAPH_UNLOCKED_STARTED,
	LEAD_TIME_GRAPH_UNLOCKED_SUCCESS,
	MAIN_PART_UPDATE_CHAIN_BENEFITS,
	MATERIAL_COMPARISON_SHOW_ALL_CLICKED,
	MATERIAL_VALUE_CHANGED_UPLOAD_PROJ,
	NEW_SOLUTION_CONFIGURATION_ADDED,
	ORIENTATION_CONFIRM_NO_CHANGE,
	OUT_SOURCED_CNC_MANUFACTURING_TOGGLE_CHANGE,
	PART_IMAGE_TABS_CLICKED,
	SEND_PART_TO_CONSUMER_REMOVE,
	SOLUTION_ANALYSIS_PART_DISABLED,
	SOLUTION_CONFIGURATION_SOLUTION_UPDATED,
	SOLUTION_CONFIGURATION_UPDATED,
	SOLUTION_CONFIGURE_BACK_CLICKED,
	SOLUTION_CONFIGURE_CALCULATION_CLICKED,
	SOLUTION_CONFIGURE_CALCULATION_FAILED,
	SOLUTION_CONFIGURE_CANCELLED,
	SOLUTION_CONFIGURE_CHANGE_ORIENTATION_FEATURE_CANCELED,
	SOLUTION_CONFIGURE_CHANGE_ORIENTATION_FEATURE_CLICKED,
	SOLUTION_CONFIGURE_CLICKED,
	SOLUTION_CONFIGURE_FILTER_FEATURE_CLICKED,
	SOLUTION_CONFIGURE_FILTER_MODAL_CANCELED,
	SOLUTION_CONFIGURE_FILTER_MODAL_CONFIRMED,
	SOLUTION_CONFIGURE_ORIENTATION_PRINTING_ALERT_CANCELED,
	SOLUTION_CONFIGURE_ORIENTATION_PRINTING_ALERT_CONFIRM,
	SOLUTION_CONFIGURE_ORIENTATION_PRINTING_CHANGE,
	SOLUTION_CONFIGURE_ORIENTATION_PRINTING_POP_UP_CANCELED,
	SOLUTION_CONFIGURE_ORIENTATION_PRINTING_POP_UP_CONFIRM,
	SOLUTION_CONFIGURE_POST_PROCESSES_CANCELED,
	SOLUTION_CONFIGURE_POST_PROCESSES_FEATURE_CLICKED,
	SOLUTION_CONFIGURE_POST_PROCESSES_MODAL_CONFIRMED,
	SOLUTION_CONFIGURE_POST_PROCESSES_TOGGLED,
	SOLUTION_CONFIGURE_PRESSED,
	SOLUTION_CONFIGURE_PRIORITIZE_CHANGED,
	SOLUTION_CONFIGURE_PRIORITIZE_FEATURE_CLICKED,
	SOLUTION_CONFIGURE_PRIORITIZE_MODAL_CANCELED,
	SOLUTION_CONFIGURE_PRIORITIZE_MODAL_CONFIRMED,
	SOLUTION_CONFIGURE_PRIORITY_REMOVED,
	SOLUTION_CONFIGURE_PRIORITY_TOGGLED,
	SOLUTION_CONFIGURE_RESET_CLICKED,
	SOLUTION_CONFIGURE_SHOW_ORIENTATION_PRINTING_MODEL,
	SOLUTION_CONFIGURE_SHOW_POP_UP,
	SOLUTION_CONFIGURE_STATE_UPDATED,
	SOLUTION_DO_SETUP,
	SOLUTION_FETCHED,
	SOLUTION_NAME_CHANGED,
	SOLUTION_NAME_FOCUSED_IN,
	SOLUTION_NAME_FOCUSED_OUT,
	SOLUTION_NAME_UPDATED,
	SOLUTION_ORIENTATION_CLICKED,
	SOLUTION_PRESSED,
	SOLUTION_PRINTER_MATERIAL_CONFIGURE_CANCELLED,
	SOLUTION_PRINTER_MATERIAL_CONFIGURE_PRESSED,
	SOLUTION_RETRIEVE_RESULTS_CLICKED,
	SOLUTION_TOUR_STEP_UPDATED,
	SOLUTION_UNMOUNTED,
	START_FETCHING_CONFIGURATION_ORGANIZATION_SETTINGS,
	START_POLLING_TRAY_ORIENTATION_DATA,
	TOGGLE_ASSEMBLING_COST_MODAL,
	TOLERANCES_CANCELLED,
	TOLERANCES_CLICKED,
	TOOLING_TOGGLE_CHANGE_SUCCESS,
	TOOLING_TOGGLE_RESET,
	TRAY_ORIENTATION_INFO_BUTTON_CLICKED
} from 'global actions/types/partAnalysisTypes'
import { PART_IMAGES_TABS_INDEX, partResults } from 'Services/Constants'
import { toFixedOnlyIfNeeded } from 'Services/global/toFixedOnlyIfNeeded'
import { IConfigurationColors } from 'Services/models/ConfigurationColors'
import {
	IChartData,
	IChartLeadData
} from 'Services/models/CostComparisonModels'
import {
	ChainBenefitsNames,
	IChainBenefits
} from 'Services/models/IChainBenefits'
import { AssemblingParams } from 'Services/models/IConfiguration'
import { IFilter, ISolutionFilter } from 'Services/models/IFilter'
import { IOrganization } from 'Services/models/IOrganization'
import { OrientationData } from 'Services/models/IOrinetationData'
import { SupplyChainResultObject } from 'Services/models/ISupplyChain'
import { PART_ANALYSIS_LED_WITH_TITLE_INDEXES } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

const { tabOrderActive } = getTheme()
const { STRUCTURAL_LIMITATION_TAB_INDEX } = PART_IMAGES_TABS_INDEX
const solutionAnalysisReducerService = new SolutionAnalysisReducerService()

export class SolutionAnalysisInitialState {
	readonly configurationChanged: boolean = false
	readonly showSolutionDetails: boolean = false
	readonly solution: any = null
	readonly enableSolutionButtons: boolean = false
	readonly leadingByUserChoice: boolean = false
	readonly isLeading: boolean = false
	readonly resultTitle: string = ''
	readonly resultBody: string = ''
	readonly color: string = ''
	readonly colorOnBackground: string = ''
	readonly fetched: boolean = false
	readonly disablePart: boolean = false
	readonly newConfiguration: boolean = false
	readonly editMode: boolean = false
	readonly showConfigure: boolean = false
	readonly loadingCalculation: boolean = false
	readonly loadingCalculationPostProcess: boolean = false
	readonly solutionName: string = ''
	readonly actualResult: any = 0
	readonly solutionPriorities: Map<string, number> = new Map<string, number>()
	readonly filterInitialValues: ISolutionFilter = {
		cost: 0,
		strength: 0,
		weight: 0
	}
	readonly PostProcessOnCounter: number = 0
	readonly originalPostProcessValues: any = {}
	readonly showMaterialsFilters: boolean = false
	readonly showAmMaterialsFilters: boolean = false
	readonly showPostProcessesModal: boolean = false
	readonly showChangeOrientationModal: boolean = false
	readonly showFilterFeaturesModal: boolean = false
	readonly showPrioritiesFeaturesModal: boolean = false
	readonly showHeatTreatmentAlert: boolean = false
	readonly showSolutionPostProcess: any = {}
	readonly showSolutionPriorities: any = {}
	readonly tempSolutionPostProcessToggles: any = {}
	readonly tempSolutionPrioritiesToggles: any = {}
	readonly configurationId: number = 0
	readonly materialComparisonRows: any[][] = []
	readonly analysisResultsRows: any[][] = []
	readonly failedPrintIssuesIds: number[] = []
	readonly orientationAnalysisResultsRows: any[][] = []
	readonly initialSolutionBatchSize: number = 0
	readonly batchSize: number = 0
	readonly initialMaterial: any = {}
	readonly initialSubCategory: any = {}
	readonly chosenMaterialType: string = ''
	readonly chosenAmMaterialType: string = ''
	readonly chosenMaterialCategory: string = ''
	readonly chosenAmMaterialCategory: string = ''
	readonly chosenMaterial: any = {}
	readonly chosenSubCategory: any = {}
	readonly chooseAmSpecifyMaterial: any = false
	readonly materialTypesList: Array<string> = []
	readonly amMaterialTypesList: Array<string> = []
	readonly categoriesList: Array<string> = []
	readonly amCategoriesList: Array<string> = []
	readonly materialsList: Array<any> = []
	readonly amSubCategories: Array<any> = []
	readonly costFormMode: boolean = true
	readonly leadTimeFormMode: boolean = true
	readonly chartData: IChartData | null = null
	readonly chartLeadData: IChartLeadData | null = null
	readonly animateChartData: boolean = false
	readonly costAffectivePoint: number = 0
	readonly costAffectivePointQuantity: number = 0
	readonly printCostQuantity: number = 0
	readonly formParameters: any = {}
	readonly showCostFormCancelButton: boolean = false
	readonly showLeadFormCancelButton: boolean = false
	readonly configurationResult: string = ''
	readonly showAnalysis: boolean = false
	readonly showBenefits: boolean = true
	readonly costBenefit: any = undefined
	readonly timeBenefit: any = undefined
	readonly costDetails: any = undefined
	readonly initialSelectedTab: number = 0
	readonly showAllMaterialCompars: boolean = false
	readonly doSetup: boolean = false
	readonly showWallThicknessDemand: boolean = false
	readonly wallThicknessLoading: boolean = false
	readonly wallThicknessTestInMM: number = 0
	readonly show3dIframe: boolean = false
	readonly showHeatMapButton: boolean = false
	readonly show3dButton: boolean = false
	readonly configurationImageUrl: string = ''
	readonly ledWithTitleIndexes: any = PART_ANALYSIS_LED_WITH_TITLE_INDEXES
	readonly orientationsData: OrientationData[] = []
	readonly PrintingOrientationAlert: boolean = false
	readonly printingOrientationCalc: boolean = false
	readonly chosenOrientationVector: Array<number> = []
	readonly chosenOrientation: OrientationData | null = null
	readonly newSupportVolume: number = 0
	readonly manufacturingMethod: string = ''
	readonly trayOrientationCustom: boolean = false
	readonly trayOrientationLoader: boolean = false
	readonly defaultOrientation: string = '0'
	readonly costLoading: boolean = false
	readonly leadTimeLoading: boolean = false
	readonly unlockLeadTimeGraph: boolean = false
	readonly leadTimeError: boolean = false
	readonly wallThickessModelURL: string = ''
	readonly loadingPrintingOrientationAlert: boolean = false
	readonly alternativeSolutions: Array<any> = []
	readonly alternativeSolutionsPrintersList: Array<any> = []
	readonly simpleConfiguration: boolean = false
	readonly customConfiguration: boolean = false
	readonly simpleConfigurationPrintersCompanies: Array<string> = []
	readonly printersCompaniesList: Array<string> = []
	readonly simpleConfigurationPrinters: Array<any> = []
	readonly simpleConfigurationMaterialsList: Array<any> = []
	readonly simpleConfigurationSelectorCompanyValue: string = ''
	readonly simpleConfigurationSelectorPrinterValue: string = ''
	readonly simpleConfigurationSelectorMaterialValue: string = ''
	readonly simpleInhouseConfiguration: boolean = false
	readonly disableAdvancedButtons: boolean = false
	readonly configurationNameEdited: boolean = false
	readonly chainBenefitEditType: string = ''
	readonly showChainBenefitEditPopup: boolean = false
	readonly orientationModalExplanationText: string = ''
	readonly effectivePointIndex: number = -1
	readonly supplyChainCostsDetailsTM: SupplyChainResultObject | null = null
	readonly feaNewAnalysisLoad: boolean = false
	readonly feaOldAnalysisLoad: boolean = false
	readonly feaAnalysisClicked: boolean = false
	readonly showFeaAnalysisAlert: boolean = false
	readonly solutionFeaStrengthTitle: string = ''
	readonly solutionFeaUserInputTitle: string = ''
	readonly solutionFeaStrength: number = 0
	readonly solutionFeaSDStrength: number = 0
	readonly solutionFeaSliderUnits: string = ''
	readonly solutionFeaSliderMarks: Record<string, string> = {}
	readonly solutionFeaSliderMaxValue: number = 0
	readonly solutionFeaSliderMinValue: number = 0
	readonly solutionFeaSliderValue: number = 0
	readonly solutionFeaSliderStartValue: number = 0
	readonly solutionFeaResult: number = 0
	readonly solutionFeaSliderIsRange: boolean = false
	readonly solutionFeaAlertLoading: boolean = false
	readonly solutionFeaSelectedValues: number[] = []
	readonly feaAnalysisResultsId: string = ''
	readonly showFeaAnalysisOldAnalysis: boolean = false
	readonly outsourcedCNCManufacturingToggle: boolean = false
	readonly chainBenefitsFormLoading: boolean = false
	readonly solutionPartImageIndex: number = STRUCTURAL_LIMITATION_TAB_INDEX
	readonly orientationInfoModelShow: boolean = false
	readonly showTolerancesChangerModel: boolean = false
	readonly tolerancesLoading: boolean = false
	readonly tourStepIndex: number = 0
	readonly showWeightReduceProgress: boolean = false
	readonly showWeightReductionButton: boolean = false
	readonly freezeConfiguration: boolean = false
	readonly showGeometryAnalysisReviewAndFixesAlert: boolean = false
	readonly printIssueLoaders: Record<string, boolean> = {}
	readonly disableRibbonInfo?: boolean = false
	readonly disableConfiguration: boolean = false
	readonly loadingOrientation: boolean = false
	readonly disableCalculateButton: boolean = false
	readonly isCostEffective: boolean = false
	readonly effectiveQuantity: any = {}
	readonly filters: Array<IFilter> = []
	readonly disableTabUpdate: boolean = false
	readonly chainBenefits: IChainBenefits | any = {}
	readonly tempChainBenefits: IChainBenefits | any = {}
	readonly expectedYearsOfDemand: number = 0
	readonly isTooling: boolean = false
	readonly includeSupplyChainCosts: boolean = false
	readonly printerMaterialID: any = null
	readonly keepMaterialForInHouseClick: boolean = false
	readonly productLifeCycle: string = ''
	readonly selectedProductLifeCycle: string = ''
	readonly isSpecifiedQuantity: boolean = true
	readonly standardCost: number = 0
	readonly export3DLink: string = ''
	readonly assemblingParams: null | AssemblingParams = null
	readonly showAssemblingCostAlert: boolean = false
	readonly isAmOriginalMaterial: boolean = false
	readonly usedOrganization: IOrganization = {} as IOrganization
	readonly calculateWithOrganizationId: number = 0
	readonly organizationSettingsLoading: boolean = false
	readonly showOrganizationTMMaterialWarning: boolean = false
}

const initialState: SolutionAnalysisInitialState =
	new SolutionAnalysisInitialState()

const solutionAnalysisReducer = (
	state = initialState,
	{ type, payload }: Action<any>
) => {
	switch (type) {
		case SOLUTION_FETCHED: {
			window.scrollTo(0, 0)
			const {
				configuration,
				id,
				color,
				colorOnBackground,
				resultTitle,
				resultBody,
				solutionName,
				newSolution,
				solutionPriorities,
				supplyChainTraditionalDetails,
				PostProcessOnCounter,
				prioritiesToggles,
				postProcessToggles,
				configurationId,
				solution,
				costBenefit,
				timeBenefit,
				costDetails,
				initialSolutionBatchSize,
				chosenMaterial,
				chosenSubCategory,
				initialMaterial,
				initialSubCategory,
				chosenMaterialCategory,
				chosenAmMaterialCategory,
				chosenMaterialType,
				chosenAmMaterialType,
				materialTypesList,
				amMaterialTypesList,
				categoriesList,
				amCategoriesList,
				materialsList,
				amSubCategories,
				part,
				actualResult,
				formParameters,
				chartData,
				chartLeadData,
				configurationResult,
				initialSelectedTab,
				analysisResultsRows,
				failedPrintIssuesIds,
				manufacturingMethod,
				trayOrientationCustom,
				chosenOrientationVector,
				alternativeSolutions,
				alternativeSolutionsPrintersList,
				printersCompaniesList,
				simpleConfiguration,
				customConfiguration,
				simpleConfigurationMaterialsList,
				simpleConfigurationSelectorMaterialValue,
				simpleConfigurationPrinters,
				simpleConfigurationSelectorCompanyValue,
				simpleConfigurationSelectorPrinterValue,
				simpleInhouseConfiguration,
				printCostQuantity,
				solutionFeaStrengthTitle,
				solutionFeaStrength,
				solutionFeaSDStrength,
				solutionFeaUserInputTitle,
				solutionFeaSliderUnits,
				solutionFeaSliderMarks,
				solutionFeaSliderMaxValue,
				solutionFeaSliderValue,
				solutionFeaSliderStartValue,
				solutionFeaSliderIsRange,
				solutionFeaSliderMinValue,
				solutionFeaResult,
				solutionFeaSelectedValues,
				feaAnalysisResultsId,
				showFeaAnalysisOldAnalysis,
				chosenOrientation,
				showFeaAnalysisAlert,
				showWeightReductionButton,
				disableRibbonInfo,
				disableConfiguration,
				disablePart,
				effectiveQuantity,
				filters,
				userProviders,
				isCostEffective,
				configurationOrganizationSettings,
				leadingByUserChoice,
				isAmOriginalMaterial,
				usedOrganization,
				unitSystem
			} = payload
			const chooseAmSpecifyMaterial = !!chosenSubCategory?.chosenMaterialId
			let leadTimeFormMode = true
			const showHeatMapButton = !!configuration.heatMapImageURL
			const show3dButton =
				!!configuration.wallThickessModelURL && !configuration.cluster
			const configurationImageUrl: string = configuration.heatMapImageURL || ''
			const outsourcedCNCManufacturingToggle: boolean =
				configuration.outsourcedCNCManufacturing || false
			let materialComparisonRows = null
			let ledWithTitleIndexes = null
			let costComparisonData: any = {}

			const productLifeCycle = configuration.productLifeCycle
			materialComparisonRows =
				solution && solution.id
					? solutionAnalysisReducerService.getMaterialComparisonRows(
							solution,
							configuration.material,
							solutionPriorities,
							false,
							isAmOriginalMaterial,
							unitSystem
					  )
					: []

			if (chartData) {
				const costAffectivePoint = toFixedOnlyIfNeeded(Number(actualResult))
				costComparisonData = {
					costFormMode: false,
					chartData: cloneDeep(chartData),
					costAffectivePoint,
					animateChartData: !isEqual(chartData, state.chartData),
					costAffectivePointQuantity: initialSolutionBatchSize,
					printCostQuantity,
					effectivePointIndex: chartData?.effectivePointIndex,
					supplyChainCostsDetailsTM: supplyChainTraditionalDetails
				}
			}

			if (chartLeadData) {
				leadTimeFormMode = false
			}

			ledWithTitleIndexes = configuration.wallThicknessTestInMM
				? solutionAnalysisReducerService.formatLedWithTitleIndexesStrings(
						configuration.wallThicknessTestInMM
				  )
				: state.ledWithTitleIndexes
			const solutionPartImageIndex =
				solutionAnalysisReducerService.getTabIndex(configuration)

			const includeSupplyChainCosts =
				!!configurationOrganizationSettings?.customizationSettings
					?.includeSupplyChainCosts

			const chainBenefits = solutionAnalysisReducerService.getChainBenefits(
				configuration.chainBenefits,
				includeSupplyChainCosts
			)
			const tempChainBenefits = cloneDeep(chainBenefits)
			const isAddNewClicked = id <= 0
			const showOrganizationTMMaterialWarning =
				state.calculateWithOrganizationId !== configuration.organizationId &&
				!isEmpty(chosenMaterial) &&
				materialsList.filter(
					(material: Record<string, any>) => material.id === chosenMaterial.id
				).length === 0

			const newChosenMaterial = showOrganizationTMMaterialWarning
				? materialsList[0]
				: chosenMaterial

			return {
				...state,
				leadTimeFormMode,
				solution,
				color,
				colorOnBackground,
				resultTitle,
				leadingByUserChoice,
				isLeading: configuration.isLeading,
				resultBody,
				chartLeadData,
				fetched: true,
				newConfiguration: isAddNewClicked,
				editMode: !isAddNewClicked ? state.editMode : true,
				showSolutionDetails:
					!isAddNewClicked && !newSolution ? state.showSolutionDetails : true,
				solutionName,
				showConfigure: state.organizationSettingsLoading || isAddNewClicked,
				enableSolutionButtons: isAddNewClicked
					? false
					: newSolution
					? true
					: state.showSolutionDetails,
				solutionPriorities,
				PostProcessOnCounter,
				filters: cloneDeep(filters),
				originalPostProcessValues: postProcessToggles,
				showSolutionPostProcess: postProcessToggles,
				tempSolutionPostProcessToggles: postProcessToggles,
				showSolutionPriorities: prioritiesToggles,
				tempSolutionPrioritiesToggles: prioritiesToggles,
				configurationId,
				trayOrientationCustom,
				materialComparisonRows,
				analysisResultsRows,
				failedPrintIssuesIds,
				initialSolutionBatchSize,
				batchSize: initialSolutionBatchSize,
				chosenMaterial: newChosenMaterial,
				chosenSubCategory,
				initialMaterial,
				initialSubCategory,
				chosenMaterialCategory,
				chosenAmMaterialCategory,
				chosenMaterialType,
				chosenAmMaterialType,
				materialTypesList,
				amMaterialTypesList,
				categoriesList,
				amCategoriesList,
				materialsList,
				amSubCategories,
				isCostEffective,
				configurationResult,
				showAnalysis:
					state.showAnalysis ||
					(configuration &&
						(configuration.result === partResults.printable ||
							configuration.result === partResults.borderline ||
							disablePart)),
				showBenefits:
					configuration &&
					(configuration.result === partResults.printable ||
						configuration.result === partResults.borderline),
				costBenefit: part && costBenefit,
				costDetails,
				timeBenefit,
				initialSelectedTab,
				doSetup: false,
				...costComparisonData,
				formParameters,
				showWallThicknessDemand: false,
				wallThicknessLoading: false,
				wallThicknessTestInMM: configuration.wallThicknessTestInMM,
				ledWithTitleIndexes,
				showHeatMapButton,
				show3dButton,
				configurationImageUrl,
				manufacturingMethod,
				wallThickessModelURL: configuration.wallThickessModelURL,
				chosenOrientationVector,
				alternativeSolutions,
				alternativeSolutionsPrintersList,
				printersCompaniesList,
				simpleConfiguration,
				customConfiguration,
				simpleConfigurationSelectorMaterialValue,
				simpleConfigurationMaterialsList,
				simpleConfigurationPrinters,
				simpleConfigurationSelectorCompanyValue,
				simpleConfigurationSelectorPrinterValue,
				simpleInhouseConfiguration,
				disableAdvancedButtons: id <= 0,
				solutionFeaStrengthTitle,
				solutionFeaStrength,
				solutionFeaSDStrength,
				solutionFeaUserInputTitle,
				solutionFeaSliderUnits,
				solutionFeaSliderMarks,
				solutionFeaSliderMaxValue,
				solutionFeaSliderValue,
				solutionFeaSliderStartValue,
				solutionFeaSliderIsRange,
				solutionFeaSliderMinValue,
				solutionFeaResult,
				solutionFeaSelectedValues,
				feaAnalysisResultsId,
				showFeaAnalysisOldAnalysis,
				chosenOrientation,
				showFeaAnalysisAlert,
				outsourcedCNCManufacturingToggle,
				solutionPartImageIndex,
				showWeightReductionButton,
				disableCalculateButton: id === -1,
				disableRibbonInfo,
				disableConfiguration,
				effectiveQuantity: effectiveQuantity,
				userProviders,
				showAllMaterialCompars: false,
				unlockLeadTimeGraph: !!configuration?.unlockLeadTimeGraph,
				tempChainBenefits,
				chainBenefits,
				isTooling: configuration.isTooling,
				includeSupplyChainCosts,
				actualResult,
				productLifeCycle,
				isSpecifiedQuantity: configuration.isSpecifiedQuantity,
				standardCost: configuration.standardCost,
				assemblingParams: configuration.assemblingParams,
				isAmOriginalMaterial,
				chooseAmSpecifyMaterial,
				usedOrganization: usedOrganization || state.usedOrganization,
				calculateWithOrganizationId:
					state.organizationSettingsLoading || state.loadingCalculation
						? state.calculateWithOrganizationId
						: configuration.organizationId,
				organizationSettingsLoading: false,
				showOrganizationTMMaterialWarning,
				unitSystem
			}
		}

		case SOLUTION_DO_SETUP:
			return {
				...state,
				doSetup: true
			}

		case SOLUTION_TOUR_STEP_UPDATED:
			const { tourStepIndex } = payload
			return { ...state, tourStepIndex }

		case SOLUTION_PRESSED: {
			const { id, show } = payload
			if (show) {
				solutionAnalysisReducerService.scrollToElementInContainer(
					`scrollToSolution_${id}`
				)
			}

			let costFormMode = true
			if (state.chartData) {
				costFormMode = !Object.keys(state.chartData).length
			}

			return {
				...state,
				showAllMaterialCompars: false,
				showSolutionDetails: show,
				enableSolutionButtons: show && id > 0,
				costFormMode
			}
		}

		case GLOBAL_CHAIN_BENEFITS_CHECK_BOX_CHANGED: {
			const newOnState = !state.tempChainBenefits[ChainBenefitsNames.Global]?.on
			return {
				...state,
				tempChainBenefits: {
					...Object.entries(state.tempChainBenefits).reduce(
						(acc: any, [name, val]: Array<any>) => {
							return {
								...acc,
								[name]: {
									on: newOnState
								}
							}
						},
						{}
					)
				}
			}
		}
		case CHAIN_BENEFITS_CHECK_BOX_CHANGED: {
			const { chainBenefitName, chainBenefitStatus } = payload
			let tempChainBenefits = {
				...state.tempChainBenefits,
				[chainBenefitName]: {
					...state.tempChainBenefits[chainBenefitName],
					on: chainBenefitStatus
				}
			}
			const checkBoxOffCounter = Object.entries(tempChainBenefits).filter(
				(arr: Array<any>) => arr[0] !== chainBenefitName.Global && !arr[1]?.on
			)
			if (checkBoxOffCounter && checkBoxOffCounter.length > 2) {
				tempChainBenefits[ChainBenefitsNames.Global].on = true
			}

			return {
				...state,
				tempChainBenefits
			}
		}

		case COST_COMPARISON_RESET_CHAIN_BENEFITS: {
			const tempChainBenefits = { ...state.chainBenefits }
			return {
				...state,
				tempChainBenefits,
				expectedYearsOfDemand: 0
			}
		}

		case CHAIN_BENEFITS_YEARS_OF_DEMAND_CHANGED: {
			const { expectedYearsOfDemand } = payload
			return {
				...state,
				expectedYearsOfDemand
			}
		}

		case MAIN_PART_UPDATE_CHAIN_BENEFITS: {
			const { configuration } = payload
			let updatedChainBenefits = null
			if (
				configuration.chainBenefits &&
				Object.keys(configuration.chainBenefits).length
			) {
				updatedChainBenefits = { ...configuration.chainBenefits }
			}
			return {
				...state,
				chainBenefits: updatedChainBenefits || state.chainBenefits,
				tempChainBenefits: updatedChainBenefits || state.chainBenefits
				// partPrintIssues: partPrintIssues || state.partPrintIssues
			}
		}

		case SOLUTION_PRINTER_MATERIAL_CONFIGURE_PRESSED:
		case SOLUTION_CONFIGURE_PRESSED: {
			const { id } = payload
			solutionAnalysisReducerService.scrollToElementInContainer(
				`scrollToSolution_${id}`
			)
			return {
				...state,
				showSolutionDetails: true,
				simpleConfiguration: id === -1,
				doSetup: true
			}
		}

		case SOLUTION_CONFIGURE_CANCELLED:
		case SOLUTION_PRINTER_MATERIAL_CONFIGURE_CANCELLED:
			return { ...state, showSolutionDetails: false }

		case SOLUTION_NAME_FOCUSED_OUT:
			return {
				...state,
				editMode: false
			}

		case SOLUTION_NAME_CHANGED:
		case SOLUTION_NAME_UPDATED:
			const { solutionName } = payload
			return {
				...state,
				solutionName
			}

		case SOLUTION_NAME_FOCUSED_IN: {
			return {
				...state,
				editMode: true,
				configurationNameEdited: true
			}
		}
		case START_POLLING_TRAY_ORIENTATION_DATA: {
			return {
				...state,
				trayOrientationLoader: true
			}
		}
		case END_POLLING_TRAY_ORIENTATION_DATA: {
			return {
				...state,
				trayOrientationLoader: false
			}
		}
		case SOLUTION_CONFIGURE_CLICKED: {
			const { id } = payload
			let showConfigure = !state.showConfigure
			if (!state.showSolutionDetails) {
				showConfigure = true
			}
			solutionAnalysisReducerService.scrollToElementInContainer(
				`scrollToSolution_${id}`
			)
			return {
				...state,
				showConfigure,
				showSolutionDetails: true,
				enableSolutionButtons: true
			}
		}

		case CONFIGURATION_INHOUSE_ALERT_WITH_MATERIAL:
			return {
				...state,
				keepMaterialForInHouseClick: payload.keepMaterialForInHouseClick
			}

		case SOLUTION_RETRIEVE_RESULTS_CLICKED: {
			const { id } = payload
			solutionAnalysisReducerService.scrollToElementInContainer(
				`scrollToSolution_${id}`
			)
			return {
				...state,
				showSolutionDetails: true,
				enableSolutionButtons: true,
				showAnalysis: true
			}
		}

		case SOLUTION_CONFIGURE_BACK_CLICKED: {
			return {
				...state,
				showConfigure: false
			}
		}
		case SOLUTION_CONFIGURE_RESET_CLICKED: {
			const {
				postProcessToggles,
				prioritiesToggles,
				solutionPriorities,
				materialTypesList,
				amMaterialTypesList,
				categoriesList,
				amCategoriesList,
				materialsList,
				amSubCategories,
				PostProcessOnCounter,
				printersCompaniesList,
				simpleConfiguration,
				simpleConfigurationMaterialsList,
				simpleConfigurationSelectorMaterialValue,
				simpleConfigurationPrinters,
				simpleConfigurationSelectorCompanyValue,
				simpleConfigurationSelectorPrinterValue,
				simpleInhouseConfiguration
			} = payload
			let chosenMaterialType = state.initialMaterial?.type
			let chosenMaterialCategory = state.initialMaterial?.category
			let chosenMaterial = state.initialMaterial

			let chosenAmMaterialType = state.initialSubCategory?.type
			let chosenAmMaterialCategory = state.initialSubCategory?.category
			let chosenSubCategory = state.initialSubCategory
			// need to keep material if we go to the settings from filter
			if (state.keepMaterialForInHouseClick) {
				chosenMaterialType = state.chosenMaterial?.type
				chosenMaterialCategory = state.chosenMaterial?.category
				chosenMaterial = state.chosenMaterial

				chosenAmMaterialType = state.chosenSubCategory?.type
				chosenAmMaterialCategory = state.chosenSubCategory?.category
				chosenSubCategory = state.chosenSubCategory
			}

			return {
				...state,
				configurationChanged: false,
				solutionPriorities,
				showSolutionPostProcess: postProcessToggles,
				originalPostProcessValues: postProcessToggles,
				tempSolutionPostProcessToggles: postProcessToggles,
				showSolutionPriorities: prioritiesToggles,
				tempSolutionPrioritiesToggles: prioritiesToggles,
				batchSize: state.initialSolutionBatchSize || '',
				chosenMaterialType,
				chosenAmMaterialType,
				chosenMaterialCategory,
				chosenAmMaterialCategory,
				chosenMaterial,
				chosenSubCategory,
				materialTypesList,
				amMaterialTypesList,
				categoriesList,
				amCategoriesList,
				materialsList,
				amSubCategories,
				PostProcessOnCounter,
				printersCompaniesList,
				simpleConfiguration,
				simpleConfigurationMaterialsList,
				simpleConfigurationSelectorMaterialValue,
				simpleConfigurationPrinters,
				simpleConfigurationSelectorCompanyValue,
				simpleConfigurationSelectorPrinterValue,
				simpleInhouseConfiguration,
				showOrganizationTMMaterialWarning: false
			}
		}
		case SOLUTION_CONFIGURE_PRIORITIZE_CHANGED:
			const { solutionPriorities } = payload
			return {
				...state,
				solutionPriorities,
				configurationChanged: true
			}
		case SOLUTION_CONFIGURE_CALCULATION_CLICKED:
			const { popUpCalculation, loadingPrintingOrientationAlert } = payload
			if (popUpCalculation) {
				return {
					...state,
					loadingCalculationPostProcess: true,
					costLoading: true,
					loadingPrintingOrientationAlert,
					showOrganizationTMMaterialWarning: false
				}
			}
			return {
				...state,
				loadingCalculation: true,
				costLoading: true,
				showOrganizationTMMaterialWarning: false
			}

		case SOLUTION_ANALYSIS_PART_DISABLED:
			const { pollerStarted } = payload
			return {
				...state,
				...payload,
				showSolutionDetails: pollerStarted ? false : state.showSolutionDetails,
				enableSolutionButtons: pollerStarted
					? false
					: state.enableSolutionButtons
			}
		case SOLUTION_CONFIGURATION_SOLUTION_UPDATED: {
			const {
				chartData,
				chartLeadData,
				actualResult,
				supplyChainTraditionalDetails,
				formParameters,
				solution,
				costBenefit,
				timeBenefit,
				configuration,
				postProcessToggles,
				PostProcessOnCounter,
				optionalPostProcessAvailability,
				allOptionalPostProcessData,
				optionalPostProcessesBreakDown,
				initialSelectedTab = state.initialSelectedTab,
				analysisResultsRows,
				failedPrintIssuesIds,
				manufacturingMethod,
				trayOrientationCustom,
				alternativeSolutions,
				printCostQuantity,
				chosenOrientation,
				chosenOrientationVector,
				keepForm,
				showWeightReductionButton,
				costDetails,
				effectiveQuantity,
				isCostEffective,
				disableTabUpdate,
				tabIndex,
				usedOrganization,
				unitSystem
			} = payload

			let alternativeSolutionsPrintersList = null
			const selectedSolution = solution || configuration.solution
			const configurationImageUrl: string = configuration.heatMapImageURL || ''
			const costAffectivePoint = toFixedOnlyIfNeeded(Number(actualResult))
			let costComparisonData: any = {
				costFormMode: keepForm || (chartData && !Object.keys(chartData).length),
				leadTimeFormMode:
					keepForm || (chartLeadData && !Object.keys(chartLeadData).length),
				chartData,
				costAffectivePoint,
				formParameters,
				costAffectivePointQuantity: state.batchSize,
				printCostQuantity,
				effectivePointIndex: chartData && chartData.effectivePointIndex,
				supplyChainCostsDetailsTM: supplyChainTraditionalDetails
			}
			let materialComparisonRows: any[] = []

			let configurationSolution: any = solution
			if (!configurationSolution && configuration.solution) {
				configurationSolution = state.solution
			}

			if (configurationSolution && configurationSolution.id) {
				materialComparisonRows =
					solutionAnalysisReducerService.getMaterialComparisonRows(
						configurationSolution,
						configuration.material,
						state.solutionPriorities,
						state.showAllMaterialCompars,
						state.isAmOriginalMaterial,
						unitSystem
					)
			}

			const solutionAnalysisService = new SolutionAnalysisService(
				configuration,
				optionalPostProcessAvailability,
				allOptionalPostProcessData,
				optionalPostProcessesBreakDown
			)
			const colors: IConfigurationColors =
				solutionAnalysisService.getSolutionColor()
			const resultTitle: string =
				solutionAnalysisService.getSolutionResultTitle()
			const configurationResult: string =
				solutionAnalysisService.getSolutionResult()
			const resultBody: string = solutionAnalysisService.getSolutionResultBody()
			alternativeSolutionsPrintersList =
				solutionAnalysisService.generateAlternativeSolutionsPrintersList(
					alternativeSolutions,
					selectedSolution
				)
			const ledWithTitleIndexes = configuration.wallThicknessTestInMM
				? solutionAnalysisReducerService.formatLedWithTitleIndexesStrings(
						configuration.wallThicknessTestInMM
				  )
				: state.ledWithTitleIndexes

			const solutionPartImageIndex =
				solutionAnalysisReducerService.getTabIndex(configuration)

			const chainBenefits = solutionAnalysisReducerService.getChainBenefits(
				configuration.chainBenefits,
				state.includeSupplyChainCosts
			)

			return {
				...state,
				trayOrientationCustom,
				loadingCalculation: false,
				loadingCalculationPostProcess: false,
				costLoading: false,
				solution: configurationSolution,
				costBenefit: costBenefit === null ? state.costBenefit : costBenefit,
				timeBenefit: timeBenefit === null ? state.timeBenefit : timeBenefit,
				costDetails,
				showLeadFormCancelButton: keepForm || false,
				showPlasticFormCancelButton: keepForm || false,
				showCostFormCancelButton: keepForm || false,
				materialComparisonRows,
				analysisResultsRows,
				failedPrintIssuesIds,
				...colors,
				resultTitle,
				configurationResult,
				resultBody,
				showConfigure: false,
				showSolutionPostProcess: postProcessToggles,
				originalPostProcessValues:
					postProcessToggles || configuration.postProcessesOptional || {},
				postProcessToggles,
				initialSelectedTab: disableTabUpdate ? tabIndex : initialSelectedTab,
				PostProcessOnCounter,
				tempSolutionPostProcessToggles: postProcessToggles,
				showAnalysis:
					configuration &&
					(configuration.result === partResults.printable ||
						configuration.result === partResults.borderline),
				showBenefits:
					configuration &&
					(configuration.result === partResults.printable ||
						configuration.result === partResults.borderline),
				configurationImageUrl,
				wallThicknessTestInMM: configuration.wallThicknessTestInMM,
				printingOrientationCalc: false,
				configurationChanged: false,
				...costComparisonData,
				manufacturingMethod,
				loadingPrintingOrientationAlert: false,
				alternativeSolutionsPrintersList,
				alternativeSolutions,
				ledWithTitleIndexes,
				wallThickessModelURL: configuration.wallThickessModelURL,
				chosenOrientationVector:
					chosenOrientationVector || chosenOrientation?.trayNormalVector,
				chosenOrientation: chosenOrientation || state.chosenOrientation,
				solutionPartImageIndex,
				showWeightReductionButton,
				disableAdvancedButtons: false,
				effectiveQuantity,
				isCostEffective,
				chartLeadData,
				disableTabUpdate: disableTabUpdate,
				unlockLeadTimeGraph: !!configuration?.unlockLeadTimeGraph,
				chainBenefits: chainBenefits,
				actualResult,
				batchSize: configuration.quantity,
				initialSolutionBatchSize: configuration.quantity,
				initialMaterial: state.chosenMaterial,
				chosenMaterialType: state.chosenMaterial?.type,
				chosenMaterialCategory: state.chosenMaterial?.category,
				chosenMaterial: state.chosenMaterial,
				initialSubCategory: state.chosenSubCategory,
				chosenAmMaterialType: state.chosenSubCategory?.type,
				chosenAmMaterialCategory: state.chosenSubCategory?.category,
				chosenSubCategory: state.chosenSubCategory,
				assemblingParams: configuration.assemblingParams,
				usedOrganization: usedOrganization || state.usedOrganization
			}
		}
		case NEW_SOLUTION_CONFIGURATION_ADDED:
		case SOLUTION_CONFIGURE_CALCULATION_FAILED: {
			const {
				popUpCalculation,
				PostProcessOnCounter,
				solutionName = state.solutionName,
				failed = false
			} = payload
			if (popUpCalculation) {
				return {
					...state,
					printingOrientationCalc: false,
					loadingCalculationPostProcess: false,
					costLoading: false,
					PostProcessOnCounter:
						PostProcessOnCounter ?? state.PostProcessOnCounter,
					solutionName,
					simpleConfigurationSelectorCompanyValue: '',
					simpleConfigurationSelectorPrinterValue: '',
					simpleConfigurationSelectorMaterialValue: '',
					simpleInhouseConfiguration: false,
					configurationNameEdited: false,
					freezeConfiguration: !failed
				}
			}
			return {
				...state,
				loadingCalculation: false,
				costLoading: false,
				solutionName
			}
		}
		case MATERIAL_VALUE_CHANGED_UPLOAD_PROJ:
			return { ...state, materialChosen: payload, BOMFile: null }

		case SOLUTION_CONFIGURE_STATE_UPDATED:
			return { ...state, ...payload }

		case SOLUTION_CONFIGURE_POST_PROCESSES_TOGGLED: {
			const { postProcessToggled } = payload

			return {
				...state,
				configurationChanged: true,
				tempSolutionPostProcessToggles: {
					...state.tempSolutionPostProcessToggles,
					[postProcessToggled.id]: {
						toggled:
							!state.tempSolutionPostProcessToggles[postProcessToggled.id]
								?.toggled,
						disabled:
							state.tempSolutionPostProcessToggles[postProcessToggled.id]
								?.disabled
					}
				}
			}
		}
		case SOLUTION_CONFIGURE_PRIORITY_TOGGLED: {
			const { priority } = payload
			return {
				...state,
				tempSolutionPrioritiesToggles: {
					...state.tempSolutionPrioritiesToggles,
					[priority.name]: !state.tempSolutionPrioritiesToggles[priority.name]
				}
			}
		}
		case SOLUTION_CONFIGURE_PRIORITY_REMOVED: {
			const { priority, solutionPriorities, unitSystem } = payload
			const newSolutionPriorities: Map<string, number> = new Map(
				solutionPriorities
			)
			newSolutionPriorities.delete(priority.name)
			const materialComparisonRows =
				solutionAnalysisReducerService.getMaterialComparisonRows(
					state.solution,
					state.chosenMaterial,
					newSolutionPriorities,
					state.showAllMaterialCompars,
					state.isAmOriginalMaterial,
					unitSystem
				)
			return {
				...state,
				configurationChanged: true,
				tempSolutionPrioritiesToggles: {
					...state.tempSolutionPrioritiesToggles,
					[priority.name]: false
				},
				showSolutionPriorities: {
					...state.showSolutionPriorities,
					[priority.name]: false
				},
				solutionPriorities: newSolutionPriorities,
				materialComparisonRows
			}
		}
		case SOLUTION_CONFIGURE_POST_PROCESSES_CANCELED:
			return {
				...state,
				showPostProcessesModal: false,
				tempSolutionPostProcessToggles: state.showSolutionPostProcess
			}

		case SOLUTION_CONFIGURE_FILTER_MODAL_CANCELED:
			return {
				...state,
				showFilterFeaturesModal: false,
				keepMaterialForInHouseClick: false
			}
		case SOLUTION_CONFIGURE_PRIORITIZE_MODAL_CANCELED:
			return {
				...state,
				showPrioritiesFeaturesModal: false,
				tempSolutionPrioritiesToggles: state.showSolutionPriorities
			}
		case SOLUTION_CONFIGURE_FILTER_MODAL_CONFIRMED: {
			return {
				...state,
				showFilterFeaturesModal: false,
				keepMaterialForInHouseClick: false
			}
		}

		case SOLUTION_CONFIGURE_POST_PROCESSES_MODAL_CONFIRMED:
			const { PostProcessOnCounter, postProcessToggles } = payload
			return {
				...state,
				showSolutionPostProcess: postProcessToggles,
				showPostProcessesModal: false,
				PostProcessOnCounter
			}
		case SOLUTION_CONFIGURE_PRIORITIZE_MODAL_CONFIRMED: {
			const { solutionPriorities, unitSystem } = payload
			const materialComparisonRows =
				solutionAnalysisReducerService.getMaterialComparisonRows(
					state.solution,
					state.chosenMaterial,
					solutionPriorities,
					state.showAllMaterialCompars,
					state.isAmOriginalMaterial,
					unitSystem
				)
			return {
				...state,
				showSolutionPriorities: state.tempSolutionPrioritiesToggles,
				showPrioritiesFeaturesModal: false,
				solutionPriorities,
				materialComparisonRows
			}
		}

		case SOLUTION_CONFIGURE_POST_PROCESSES_FEATURE_CLICKED:
			return {
				...state,
				showPostProcessesModal: true
			}
		case SOLUTION_CONFIGURE_SHOW_POP_UP: {
			const { orientationData } = payload
			return {
				...state,
				showChangeOrientationModal: true,
				orientationsData: orientationData
			}
		}
		case SOLUTION_CONFIGURE_ORIENTATION_PRINTING_POP_UP_CONFIRM: {
			return {
				...state,
				showChangeOrientationModal: false,
				printingOrientationCalc: false
			}
		}
		case SOLUTION_CONFIGURE_ORIENTATION_PRINTING_CHANGE: {
			const { chosenOrientationVector, newSupportVolume } = payload
			return {
				...state,
				chosenOrientationVector,
				newSupportVolume
			}
		}
		case ORIENTATION_CONFIRM_NO_CHANGE: {
			return {
				...state,
				showChangeOrientationModal: false,
				trayOrientationLoader: false
			}
		}
		case SOLUTION_CONFIGURE_CHANGE_ORIENTATION_FEATURE_CLICKED: {
			const {
				configurationChanged,
				orientationData,
				defaultOrientation,
				isMechanicalAnalysisCall
			} = payload

			if (configurationChanged) {
				return {
					...state,
					PrintingOrientationAlert: true,
					trayOrientationLoader: false,
					defaultOrientation
				}
			}
			return {
				...state,
				showChangeOrientationModal: true,
				orientationsData: orientationData,
				trayOrientationLoader: false,
				defaultOrientation,
				orientationModalExplanationText: isMechanicalAnalysisCall
					? getString('SOLUTION_ORIENTATION_GENERAL_TEXT_FROM_MECHANICAL')
					: getString('SOLUTION_ORIENTATION_GENERAL_TEXT')
			}
		}
		case SOLUTION_CONFIGURE_ORIENTATION_PRINTING_POP_UP_CANCELED: {
			return {
				...state,
				showChangeOrientationModal: false
			}
		}
		case SOLUTION_CONFIGURE_ORIENTATION_PRINTING_ALERT_CANCELED: {
			return {
				...state,
				PrintingOrientationAlert: false
			}
		}
		case SOLUTION_CONFIGURE_SHOW_ORIENTATION_PRINTING_MODEL: {
			const { orientationData } = payload
			return {
				...state,
				showChangeOrientationModal: true,
				orientationsData: orientationData,
				printingOrientationCalc: false,
				PrintingOrientationAlert: false
			}
		}
		case SOLUTION_CONFIGURE_ORIENTATION_PRINTING_ALERT_CONFIRM: {
			return {
				...state,
				PrintingOrientationAlert: false
			}
		}
		case SOLUTION_CONFIGURE_CHANGE_ORIENTATION_FEATURE_CANCELED:
			return {
				...state,
				showChangeOrientationModal: false
			}
		case SOLUTION_CONFIGURE_FILTER_FEATURE_CLICKED:
			return {
				...state,
				showFilterFeaturesModal: true
			}

		case LIFE_CYCLE_CHANGED:
			const { lifeCycle } = payload
			return {
				...state,
				selectedProductLifeCycle: lifeCycle || ''
			}

		case SOLUTION_CONFIGURE_PRIORITIZE_FEATURE_CLICKED:
			return {
				...state,
				showPrioritiesFeaturesModal: true
			}
		case SOLUTION_UNMOUNTED:
			return {
				...state,
				showSolutionDetails: false
			}

		case LIFE_CYCLE_FORM_SUBMITED:
			const { cycle } = payload

			return {
				...state,
				productLifeCycle: cycle
			}

		case CONFIGURATION_IS_SET_LEADING: {
			const { leadingByUserChoice, leadingConfigurationId, id } = payload

			return {
				...state,
				leadingByUserChoice,
				isLeading: leadingConfigurationId === id
			}
		}

		case BATCH_SIZE_CHANGED:
			const { batchSize } = payload

			return {
				...state,
				configurationChanged: true,
				batchSize,
				disableCalculateButton: !batchSize
			}

		case MATERIAL_TYPE_CHANGED: {
			const {
				materialTypesList,
				categoriesList,
				materialsList,
				chosenMaterialCategory,
				chosenMaterialType,
				chosenMaterial
			} = payload
			return {
				...state,
				chosenMaterialType,
				materialTypesList,
				categoriesList,
				materialsList,
				chosenMaterialCategory,
				chosenMaterial,
				configurationChanged: true
			}
		}
		case AM_MATERIAL_TYPE_CHANGED: {
			const {
				amMaterialTypesList,
				amCategoriesList,
				amSubCategories,
				chosenAmMaterialCategory,
				chosenAmMaterialType,
				chosenSubCategory
			} = payload
			return {
				...state,
				chosenAmMaterialType,
				amMaterialTypesList,
				amCategoriesList,
				amSubCategories,
				chosenAmMaterialCategory,
				chosenSubCategory,
				configurationChanged: true,
				chooseAmSpecifyMaterial: false
			}
		}
		case MATERIAL_CATEGORY_CHANGED: {
			const {
				chosenMaterialCategory,
				chosenMaterialType,
				chosenMaterial,
				materialTypesList,
				categoriesList,
				materialsList
			} = payload
			return {
				...state,
				chosenMaterialCategory,
				chosenMaterialType,
				chosenMaterial,
				materialTypesList,
				categoriesList,
				materialsList,
				configurationChanged: true
			}
		}
		case AM_MATERIAL_CATEGORY_CHANGED: {
			const { amSubCategories, chosenAmMaterialCategory, chosenSubCategory } =
				payload
			return {
				...state,
				amSubCategories,
				chosenAmMaterialCategory,
				chosenSubCategory,
				configurationChanged: true,
				chooseAmSpecifyMaterial: false
			}
		}
		case ALTERNATIVE_SOLUTION_CHANGED: {
			const { solution } = payload
			return {
				...state,
				solution
			}
		}
		case EXPORT_3D_LINK_UPDATED: {
			const { signedUrl } = payload
			return {
				...state,
				export3DLink: `DDDXpertLauncher:${signedUrl}`
			}
		}
		case MATERIAL_CHANGED: {
			const {
				chosenMaterialCategory,
				chosenMaterialType,
				chosenMaterial,
				materialTypesList,
				categoriesList,
				materialsList
			} = payload
			return {
				...state,
				chosenMaterialCategory,
				chosenMaterialType,
				chosenMaterial,
				materialTypesList,
				categoriesList,
				materialsList,
				configurationChanged: true,
				showOrganizationTMMaterialWarning: false
			}
		}
		case AM_MATERIAL_CHANGED: {
			const { chosenSubCategory } = payload
			return {
				...state,
				chosenSubCategory,
				configurationChanged: true,
				chooseAmSpecifyMaterial: true
			}
		}
		case AM_SUB_CATEGORY_CHANGED: {
			const { chosenSubCategory } = payload
			return {
				...state,
				chosenSubCategory,
				configurationChanged: true,
				chooseAmSpecifyMaterial: false
			}
		}
		case MATERIAL_SELECTOR_TOGGLED:
			const { showMaterialsFilters } = payload
			return {
				...state,
				showMaterialsFilters
			}

		case AM_MATERIAL_SELECTOR_TOGGLED:
			const { showAmMaterialsFilters } = payload
			return {
				...state,
				showAmMaterialsFilters
			}

		case LEAD_TIME_GRAPH_UNLOCKED_STARTED:
			return {
				...state,
				leadTimeLoading: true
			}

		case LEAD_TIME_GRAPH_UNLOCKED_SUCCESS: {
			const { unlockLeadTimeGraph, chartLeadData } = payload

			return {
				...state,
				chartLeadData: chartLeadData,
				unlockLeadTimeGraph: !!unlockLeadTimeGraph,
				leadTimeLoading: false,
				leadTimeError: false
			}
		}

		case LEAD_TIME_GRAPH_UNLOCKED_ERROR:
			return {
				...state,
				leadTimeLoading: false,
				leadTimeError: true
			}

		case TOOLING_TOGGLE_RESET: {
			const { isTooling } = payload

			return {
				...state,
				isTooling: isTooling
			}
		}

		case TOOLING_TOGGLE_CHANGE_SUCCESS: {
			const { isTooling } = payload
			return {
				...state,
				isTooling: isTooling,
				costLoading: false,
				leadTimeLoading: false
			}
		}

		case LEAD_TIME_EDIT_STARTED:
		case COST_COMPARISON_EDIT_STARTED:
			return {
				...state,
				costLoading: true,
				leadTimeLoading: true
			}
		case LEAD_TIME_EDIT_FINISHED:
		case COST_COMPARISON_EDIT_FINISHED:
			return {
				...state,
				costLoading: false,
				leadTimeLoading: false,
				chainBenefitsFormLoading: false
			}
		case LEAD_TIME_FORM_EDIT_CLICKED: {
			return {
				...state,
				leadTimeFormMode: true,
				showLeadFormCancelButton: true
			}
		}
		case LEAD_TIME_FORM_FORM_CANCELLED: {
			return {
				...state,
				leadTimeFormMode: false,
				showLeadFormCancelButton: false
			}
		}
		case COST_COMPARISON_EDIT_CLICKED: {
			return {
				...state,
				costFormMode: true,
				showCostFormCancelButton: true
			}
		}
		case COST_COMPARISON_FORM_CANCELLED:
			return {
				...state,
				costFormMode: false,
				showCostFormCancelButton: false,
				chainBenefitEditType: '',
				showChainBenefitEditPopup: false
			}
		case COST_COMPARISON_QUANTITY_CHANGED: {
			const { batchSize } = payload
			return {
				...state,
				batchSize,
				animateChartData: false,
				configurationChanged: true,
				disableCalculateButton: !batchSize
			}
		}

		case MATERIAL_COMPARISON_SHOW_ALL_CLICKED: {
			const { unitSystem } = payload
			const materialComparisonRows =
				solutionAnalysisReducerService.getMaterialComparisonRows(
					state.solution,
					state.chosenMaterial,
					state.solutionPriorities,
					true,
					state.isAmOriginalMaterial,
					unitSystem
				)
			return {
				...state,
				showAllMaterialCompars: true,
				materialComparisonRows
			}
		}
		case CONFIGURATION_WALL_THICKNESS_CLICKED:
			return { ...state, showWallThicknessDemand: true }
		case CONFIGURATION_WALL_THICKNESS_CANCELLED:
			return { ...state, showWallThicknessDemand: false }
		case CONFIGURATION_WALL_THICKNESS_LOADING_TOGGLED:
			return { ...state, wallThicknessLoading: !state.wallThicknessLoading }
		case CONFIGURATION_WALL_THICKNESS_LOADING_UPDATED:
			return {
				...state,
				showWallThicknessDemand: false,
				wallThicknessLoading: false
			}
		case CONFIGURATION_ANALYSIS_3D_MODAL_OPENED:
			const { isCluster } = payload
			return { ...state, show3dIframe: !isCluster }
		case CONFIGURATION_ANALYSIS_3D_MODAL_CLOSED:
			return {
				...state,
				show3dIframe: false,
				showGeometryAnalysisReviewAndFixesAlert: false
			}
		case SOLUTION_CONFIGURATION_UPDATED: {
			const { result } = payload
			const correctStatus =
				result === partResults.printable || result === partResults.borderline

			return {
				...state,
				showAnalysis: correctStatus,
				showBenefits: correctStatus,
				solutionPartImageIndex: correctStatus ? 1 : 0,
				...payload
			}
		}
		case SIMPLE_CONFIGURATION_SELECTOR_COMPANY_CHANGED: {
			const { value, printersList } = payload
			return {
				...state,
				simpleConfigurationSelectorCompanyValue: value,
				simpleConfigurationPrinters: printersList,
				simpleConfigurationMaterialsList: [],
				simpleConfigurationSelectorPrinterValue: '',
				simpleConfigurationSelectorMaterialValue: '',
				disableCalculateButton: true,
				disableAdvancedButtons: true
			}
		}
		case SIMPLE_CONFIGURATION_SELECTOR_PRINTER_CHANGED: {
			const { value, materialsList } = payload
			return {
				...state,
				simpleConfigurationSelectorPrinterValue: value,
				simpleConfigurationMaterialsList: materialsList,
				simpleConfigurationSelectorMaterialValue: '',
				disableCalculateButton: true,
				disableAdvancedButtons: true,
				solutionName:
					state.configurationNameEdited || state.configurationId !== -1
						? state.solutionName
						: value.name
			}
		}
		case SIMPLE_CONFIGURATION_SELECTOR_MATERIAL_CHANGED: {
			const { value } = payload
			return {
				...state,
				printerMaterialID: value.id,
				simpleConfigurationSelectorMaterialValue: value,
				disableCalculateButton: !value || !state.batchSize // true if value is not empty
			}
		}
		case SIMPLE_CONFIGURATION_INHOUSE_TOGGLED: {
			const { printersCompaniesList, inHouseToggle } = payload
			return {
				...state,
				simpleInhouseConfiguration: inHouseToggle,
				printersCompaniesList,
				simpleConfigurationMaterialsList: [],
				simpleConfigurationSelectorMaterialValue: '',
				simpleConfigurationPrinters: [],
				simpleConfigurationSelectorCompanyValue: '',
				simpleConfigurationSelectorPrinterValue: '',
				disableCalculateButton:
					state.simpleConfiguration || state.disableCalculateButton,
				disableAdvancedButtons: inHouseToggle
			}
		}
		case FEA_NEW_ANALYSIS_CLICKED:
			return {
				...state,
				initialSelectedTab: tabOrderActive.mechanicalTab,
				feaAnalysisClicked: true
			}
		case FEA_NEW_ANALYSIS_TOGGLED:
			return { ...state, feaNewAnalysisLoad: !state.feaNewAnalysisLoad }
		case FEA_OLD_ANALYSIS_TOGGLED:
			return { ...state, feaOldAnalysisLoad: !state.feaOldAnalysisLoad }
		case FEA_ANALYSIS_ALERT_CANCELED:
			return { ...state, showFeaAnalysisAlert: false }
		case FEA_ANALYSIS_ALERT_LOADER_TOGGLED:
			return {
				...state,
				solutionFeaAlertLoading: !state.solutionFeaAlertLoading
			}
		case FEA_ANALYSIS_DATA_FETCHED:
			const { feaValues } = payload
			return {
				...state,
				solutionFeaSelectedValues: feaValues,
				showFeaAnalysisAlert: false,
				solutionFeaAlertLoading: false,
				showFeaAnalysisOldAnalysis: true
			}
		case FEA_ANALYSIS_DATA_RESET:
			return {
				...state,
				solutionFeaSelectedValues: [],
				showFeaAnalysisAlert: false,
				solutionFeaAlertLoading: false,
				showFeaAnalysisOldAnalysis: false,
				feaAnalysisResultsId: '',
				solutionFeaResult: 0
			}
		case OUT_SOURCED_CNC_MANUFACTURING_TOGGLE_CHANGE:
			return {
				...state,
				outsourcedCNCManufacturingToggle:
					!state.outsourcedCNCManufacturingToggle
			}
		case CHAIN_BENEFITS_FORM_EDIT_CANCELLED: {
			return {
				...state,
				chainBenefitEditType: '',
				showChainBenefitEditPopup: false
			}
		}
		case CHAIN_BENEFITS_UPDATE_FINISHED: {
			return {
				...state,
				chainBenefitEditType: '',
				showChainBenefitEditPopup: false,
				chainBenefitsFormLoading: false
			}
		}
		case CHAIN_BENEFITS_UPDATE_STARTED: {
			return {
				...state,
				chainBenefitsFormLoading: true
			}
		}
		case PART_IMAGE_TABS_CLICKED: {
			const { chosenTabIndex } = payload
			return {
				...state,
				solutionPartImageIndex: chosenTabIndex
			}
		}
		case TRAY_ORIENTATION_INFO_BUTTON_CLICKED: {
			return {
				...state,
				orientationInfoModelShow: true
			}
		}
		case TOLERANCES_CLICKED:
			return {
				...state,
				showTolerancesChangerModel: true
			}
		case TOLERANCES_CANCELLED:
			return {
				...state,
				showTolerancesChangerModel: false
			}
		case CONFIGURATION_TOLERANCES_LOADING_TOGGLED:
			return {
				...state,
				tolerancesLoading: !state.tolerancesLoading
			}
		case CLOSE_PART_REDUCE_WEIGHT_PROGRESS_POPUP_SOLUTION_ANALYSIS:
			return {
				...state,
				showWeightReduceProgress: false
			}
		case OPEN_PART_REDUCE_WEIGHT_PROGRESS_POPUP_SOLUTION_ANALYSIS:
			return {
				...state,
				showWeightReduceProgress: true
			}
		case GEOMETRY_ANALYSIS_ALERT_OPENED:
			return { ...state, showGeometryAnalysisReviewAndFixesAlert: true }
		case GEOMETRY_ANALYSIS_ALERT_CANCELLED:
			return { ...state, showGeometryAnalysisReviewAndFixesAlert: false }
		case GEOMETRY_ANALYSIS_PRINT_ISSUE_LOAD_TOGGLED:
			const { partIssueId } = payload
			return {
				...state,
				printIssueLoaders: {
					...state.printIssueLoaders,
					[partIssueId]: !state.printIssueLoaders[partIssueId]
				}
			}
		case BEFORE_SEND_PART_TO_CONSUMER:
			const { providerState } = payload
			return {
				...state,
				providerState: providerState
			}
		case SEND_PART_TO_CONSUMER_REMOVE:
			return {
				...state,
				providerState: {}
			}
		case TOGGLE_ASSEMBLING_COST_MODAL:
			const { open } = payload
			return {
				...state,
				showAssemblingCostAlert: open
			}
		case ASSEMBLING_PARAMS_UPDATED: {
			const { configuration } = payload
			return {
				...state,
				assemblingParams: configuration.assemblingParams,
				showAssemblingCostAlert: false
			}
		}
		case SOLUTION_ORIENTATION_CLICKED: {
			const { loading } = payload
			return {
				...state,
				loadingOrientation: loading
			}
		}
		case CHANGE_ORGANIZATION_TO_UPDATE_CONFIGURATION: {
			const { calculateWithOrganizationId } = payload
			return {
				...state,
				calculateWithOrganizationId
			}
		}
		case START_FETCHING_CONFIGURATION_ORGANIZATION_SETTINGS: {
			return {
				...state,
				organizationSettingsLoading: true
			}
		}
		case FINISHED_FETCHING_CONFIGURATION_ORGANIZATION_SETTINGS: {
			return {
				...state,
				organizationSettingsLoading: false
			}
		}
		default:
			return state
	}
}

export default solutionAnalysisReducer
