import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import {
	ADD_MATERIALS_BOM_FILE,
	ADD_MATERIALS_BOM_LOADING,
	ADD_MATERIALS_BOM_POPUP,
	CHANGE_ORGANIZATION_NAME,
	CHANGE_ORGANIZATION_PRIVACY,
	CHANGE_USER_MATERIAL_NAME_MAPPING,
	COST_AND_LEAD_TBL_DATA_ARRANGED,
	CREATE_USER_MATERIAL_NAMES_MAPPING,
	FATCH_USER_MATERIAL_NAMES_MAPPING,
	FULL_TRAY_ASSUMPTION_DATA_ARRANGED,
	HANDLE_LOADER,
	HANDLE_NOTIFICATION,
	IN_HOUSE_PRINTERS_DATA_RECEIVED,
	NEW_ORGANIZATION_ADDED,
	ORGANIZATION_INFO_UPDATED,
	PART_FILTERING_FORM_DATA_ARRANGED,
	REMOVE_USER_MATERIAL_NAMES_MAPPING,
	RESET_SETTINGS_STATE,
	SELECT_ORGANIZATION_TO_CUSTOMIZE,
	START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
	UPDATE_ORGANIZATION_INFO_ERROR,
	UPDATE_USER_MATERIAL_NAMES_MAPPING,
	UPLOAD_PROJECT_SCENARIO_DATA_ARRANGED,
	USER_DESKTOP_MATERIAL_TOGGLED,
	USER_UNIT_SYSTEM_SELECTED,
	USER_UNITS_TYPE_SELECTED
} from '../../../global actions/types'
import {
	addNewOrganization,
	createUserMaterialNameMapping,
	getAllPrintersCompanies,
	getCustomizationMaterialPriceList,
	getCustomizationPrinterSettingsList,
	getCustomizationSettings,
	getProjectScenario,
	getProjectScenarioParameters,
	getUserMaterialNamesMapping,
	getUserProfileData,
	removeUserMaterialNameMapping,
	toggleUserDesktopPrinters,
	updateOrganizationInfo,
	updateUnitsType,
	updateUnitSystem,
	updateUserMaterialNamesMapping,
	uploadMaterialsFile
} from '../../../Services/Network'
import {
	checkSemiProfessionalPrintersToggle,
	getGlobalFilteringArray,
	getPartFilteringArray,
	setupValidationStateKeys,
	validateOrganizationName
} from './CustomizeLogic'
import { isLoggedInSuccess } from 'global actions'
import {
	ALERT_CALCULATION_STARTED,
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import { store } from 'index'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import {
	defaultSettingScenario,
	ORGANIZATION_NAME_ERROR_CODE,
	SELECTED_ORGANIZATION_LS_KEY
} from 'Services/Constants'
import { ONBOARDING_WIZARD_ROUTE } from 'Services/Constants/RoutesConstants'
import history from 'Services/history'
import {
	removeItemFromLocalStorage,
	setStringItemToLocalStorage
} from 'Services/LocalStorageService'
import { IUserMaterialNamesMapping } from 'Services/models/IMaterialNamesMapping'
import { IOrganization } from 'Services/models/IOrganization'
import { homePathRoute } from 'Services/routeFuncs'
import {
	CONFIGURATION_CALCULATION_ERROR,
	SHOW_NOTIFICATION,
	YES
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { LengthUnit, UnitSystem } from 'Services/UnitsConversionService'

const customizeResponse = {
	settings: 0,
	materialPrice: 1,
	printerSettings: 2,
	printersCompanies: 3,
	projectScenario: 4,
	projectScenarioParams: 5,
	userProfileData: 6
}

export const fetchCustomizeData = (
	costAndLeadNames: Record<string, any>,
	userSettings?: Record<string, any>,
	drawingCost2dNames?: Record<string, any>,
	printersFullData?: Record<string, any>[],
	organizationId?: number,
	disableStepChange: boolean = false
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: HANDLE_LOADER,
			payload: 1
		})
		let userId =
			store.getState().user?.userId || store.getState().user?.userDetails?.id

		const promises = [
			getCustomizationSettings(organizationId),
			getCustomizationMaterialPriceList(organizationId),
			getCustomizationPrinterSettingsList(organizationId),
			getAllPrintersCompanies(organizationId),
			getProjectScenario(organizationId),
			getProjectScenarioParameters()
		]

		if (organizationId) {
			promises.push(getUserProfileData(userId, false, organizationId))
		}

		Promise.allSettled(promises)
			.then(response => {
				handleCustomizeDataResponse(
					costAndLeadNames,
					response,
					dispatch,
					userSettings,
					drawingCost2dNames,
					printersFullData,
					disableStepChange
				)
			})
			.catch(error => {
				dispatch({
					type: HANDLE_LOADER,
					payload: -1
				})
				console.error(error)
			})
	}
}

export const onToggleDesktopPrinter = (
	desktopPrinter: boolean,
	organizationId?: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			await toggleUserDesktopPrinters(
				!desktopPrinter,
				undefined,
				organizationId
			)
			dispatch({
				type: USER_DESKTOP_MATERIAL_TOGGLED
			})
		} catch (error) {
			console.error(error)
		}
	}
}

export const onSelectedInputUnitsType = (
	selectedUnitsType: string,
	organizationId?: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const response = await updateUnitsType(selectedUnitsType, organizationId)
			const unitsType = response?.data?.userSettings?.unitsType

			dispatch({
				type: USER_UNITS_TYPE_SELECTED,
				payload: { unitsType }
			})
		} catch (error) {
			console.error(error)
		}
	}
}

export const onSelectedUnitSystem = (
	selectedUnitSystem: string,
	organizationId?: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const response = await updateUnitSystem(
				selectedUnitSystem,
				organizationId
			)
			const { unitsType, unitSystem } = response?.data?.userSettings

			dispatch({
				type: USER_UNIT_SYSTEM_SELECTED,
				payload: {
					unitsType,
					unitSystem,
					updatedForOrganizationId: organizationId
				}
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('UNIT_SYSTEM_UPDATED_SUCCESS')
				}
			})
		} catch (error: any) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: getString('UNIT_SYSTEM_UPDATED_ERROR')
				}
			})
		}
	}
}

export const fetchUserMaterialNameMapping = (organizationId?: number) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		const userMaterialNamesMapping = (
			await getUserMaterialNamesMapping(organizationId)
		)?.data?.userMaterialNamesMapping
		dispatch({
			type: FATCH_USER_MATERIAL_NAMES_MAPPING,
			payload: { userMaterialNamesMapping }
		})
	}
}

export const onCreateUserMaterialNameMapping = (
	userMaterialNameMapping: IUserMaterialNamesMapping,
	onChangeSelector: (property: string, value: any) => void,
	organizationId?: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
			payload: {
				isLoading: true
			}
		})
		try {
			const userMaterialNamesMapping = (
				await createUserMaterialNameMapping(
					userMaterialNameMapping,
					organizationId
				)
			).data.userMaterialNamesMapping

			const newUserMaterialNameMapping = userMaterialNamesMapping.filter(
				(userMaterialNameMappingObject: IUserMaterialNamesMapping) =>
					userMaterialNameMappingObject.expression ===
					userMaterialNameMapping.expression
			)

			dispatch({
				type: CREATE_USER_MATERIAL_NAMES_MAPPING,
				payload: {
					userMaterialNamesMapping,
					userMaterialNameMapping: newUserMaterialNameMapping[0]
				}
			})
			onChangeSelector('type', 'plastic')
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString(
						'USER_MATERIAL_NAME_MAPPING_UPDATED_SUCCESS'
					)
				}
			})
		} catch (error: any) {
			dispatch({
				type: START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
				payload: {
					isLoading: false
				}
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: error.internalMessage || error.validationMessage
				}
			})
		}
	}
}

export const onUpdateMaterialNameMapping = (
	_userMaterialNamesMapping: IUserMaterialNamesMapping[],
	organizationId?: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
			payload: {
				isLoading: true
			}
		})
		try {
			const userMaterialNamesMapping = (
				await updateUserMaterialNamesMapping(
					_userMaterialNamesMapping,
					organizationId
				)
			).data.userMaterialNamesMapping

			const userMaterialNameMappingUpdate = userMaterialNamesMapping.find(
				(userMaterialNameMappingObject: IUserMaterialNamesMapping) =>
					userMaterialNameMappingObject.expression ===
					_userMaterialNamesMapping[0].expression
			)

			dispatch({
				type: UPDATE_USER_MATERIAL_NAMES_MAPPING,
				payload: {
					userMaterialNamesMapping,
					userMaterialNameMappingUpdate
				}
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString(
						'USER_MATERIAL_NAME_MAPPING_UPDATED_SUCCESS'
					)
				}
			})
		} catch (error: any) {
			console.log(error)
			dispatch({
				type: START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
				payload: {
					isLoading: false
				}
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage:
						error.validationMessage ||
						getString('USER_MATERIAL_NAME_MAPPING_UPDATED_FAILED')
				}
			})
		}
	}
}

export const onChangeSelectedMaterial = (
	userMaterialNameMapping: IUserMaterialNamesMapping[]
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: CHANGE_USER_MATERIAL_NAME_MAPPING,
				payload: {
					userMaterialNameMapping
				}
			})
		} catch (error) {
			console.log(error)
		}
	}
}

export const onRemoveUserMaterialNameMapping = (
	id?: string,
	fromAlert?: boolean,
	active?: boolean,
	organizationId?: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
			payload: {
				isLoading: true
			}
		})
		try {
			const userMaterialNamesMapping = (
				await removeUserMaterialNameMapping(id, active, organizationId)
			).data.userMaterialNamesMapping

			dispatch({
				type: REMOVE_USER_MATERIAL_NAMES_MAPPING,
				payload: { userMaterialNamesMapping }
			})
			if (fromAlert) {
				dispatch({ type: ALERT_POPUP_CANCELED })
			}
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString(
						'USER_MATERIAL_NAME_MAPPING_REMOVED_SUCCESS'
					)
				}
			})
		} catch (error: any) {
			console.log(error)
			if (fromAlert) {
				dispatch({ type: ALERT_POPUP_CANCELED })
			}
			dispatch({
				type: START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
				payload: {
					isLoading: false
				}
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage:
						error.validationMessage ||
						getString('USER_MATERIAL_NAME_MAPPING_REMOVED_FAILED')
				}
			})
		}
	}
}

export const onRemoveAllUserMaterialNameMapping = (
	active: boolean,
	organizationId?: number
) => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: getString(
					active
						? 'REMOVE_ALL_WARNING_DESCRIPTION'
						: 'REMOVE_ALL_INACTIVE_MATERIALS_WARNING_DESCRIPTION'
				),
				headerTitle: getString('CONFIGURATION_CHANGES_WARNING'),
				alertType: AlertType.WARNING,
				showCancel: true,
				onConfirm: () => {
					dispatch({
						type: ALERT_CALCULATION_STARTED
					})
					dispatch(
						onRemoveUserMaterialNameMapping(
							undefined,
							true,
							active,
							organizationId
						)
					)
				},
				confirmText: YES
			}
		})
	}
}

const handleCustomizeDataResponse = (
	costAndLeadNames: Record<string, any>,
	response: Record<string, any>[],
	dispatch: Dispatch<AnyAction>,
	userSettings?: Record<string, any>,
	drawingCost2dNames?: Record<string, any>,
	printersFullData?: Record<string, any>,
	disableStepChange?: boolean
) => {
	const settingsResponse = response[customizeResponse.settings].value
	const materialPriceResponse = response[customizeResponse.materialPrice].value
	const printerSettingsResponse =
		response[customizeResponse.printerSettings].value
	const printersCompanies =
		response[customizeResponse.printersCompanies].value?.data
			?.printerCompanies || []
	const printers = Object.values(printersCompanies).flat()
	const settingsData = settingsResponse?.data
	const originalUserSettingsValues = settingsData.userSettings
	const partFilteringArr = getPartFilteringArray(settingsData)
	const globalOffTheShelfSubstrings =
		settingsData.userSettings.globalOffTheShelfSubstrings
	const filteredGlobalOffTheShelf = getGlobalFilteringArray(
		globalOffTheShelfSubstrings
	)
	const materialPriceData = materialPriceResponse?.data
	const userPrinterSettingsData =
		printerSettingsResponse?.data?.userPrinterSettingsList || []
	const validationStateKeys = {}
	const defaultProjectScenario =
		response[customizeResponse.projectScenario].value?.data
			?.defaultProjectScenario || defaultSettingScenario.lowVolume
	const baseDefaultProjectScenarios =
		response[customizeResponse.projectScenario].value?.data
			?.baseDefaultProjectScenarios
	const defaultProjectScenarioParameters =
		response[customizeResponse.projectScenarioParams].value?.data
			?.defaultProjectScenarioParams
	const userProfileData = response[customizeResponse.userProfileData]?.value
	const isShowSemiProfessionalPrintersToggle =
		checkSemiProfessionalPrintersToggle(printersFullData)
	const selectedUnitsType = settingsData.userSettings.unitsType || LengthUnit.mm
	const selectedUnitSystem =
		settingsData.userSettings.unitSystem || UnitSystem.metric

	if (userProfileData) {
		isLoggedInSuccess(dispatch, userProfileData, disableStepChange, true)
	}

	setupValidationStateKeys(
		settingsData,
		costAndLeadNames,
		validationStateKeys,
		drawingCost2dNames
	)

	dispatch({
		type: COST_AND_LEAD_TBL_DATA_ARRANGED,
		payload: {
			settingsData,
			materialCosts: materialPriceData?.priceList,
			originalUserSettingsValues,
			validationStateKeys,
			userPrinterSettingsData,
			userSettings,
			isShowSemiProfessionalPrintersToggle,
			selectedUnitsType,
			selectedUnitSystem
		}
	})
	dispatch({
		type: PART_FILTERING_FORM_DATA_ARRANGED,
		payload: {
			partFilteringArr,
			filteredGlobalOffTheShelf
		}
	})
	dispatch({
		type: IN_HOUSE_PRINTERS_DATA_RECEIVED,
		payload: { printersCompanies, printers }
	})
	dispatch({
		type: FULL_TRAY_ASSUMPTION_DATA_ARRANGED,
		payload: { settingsData }
	})
	dispatch({
		type: UPLOAD_PROJECT_SCENARIO_DATA_ARRANGED,
		payload: {
			defaultProjectScenario,
			defaultProjectScenarioParameters,
			baseDefaultProjectScenarios
		}
	})
	dispatch({
		type: HANDLE_LOADER,
		payload: -1
	})
}

export const uploadMaterialsNameBom = (file: File | null) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: ADD_MATERIALS_BOM_FILE,
			payload: file
		})
	}
}

export const openMaterialsNamePopup = (isOpen: boolean) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: ADD_MATERIALS_BOM_POPUP,
			payload: isOpen
		})
	}
}

export const sendMaterialsNameBom = (
	sendFile: File,
	organizationId?: number
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		if (!sendFile) {
			return
		}
		dispatch({
			type: ADD_MATERIALS_BOM_LOADING,
			payload: true
		})
		dispatch({
			type: ADD_MATERIALS_BOM_FILE,
			payload: null
		})

		let reader = new FileReader()
		reader.readAsDataURL(sendFile)
		reader.onload = async () => {
			try {
				const response = await uploadMaterialsFile(
					reader?.result,
					sendFile.name,
					organizationId
				)
				if (response.data) {
					dispatch({
						type: FATCH_USER_MATERIAL_NAMES_MAPPING,
						payload: {
							userMaterialNamesMapping: response.data.userMaterialNamesMapping
						}
					})
					dispatch({
						type: HANDLE_NOTIFICATION,
						payload: {
							notificationType: SHOW_NOTIFICATION.SUCCESS,
							notificationMessage: getString('ADD_MULTIPLE_MATERIALS_SUCCESS')
						}
					})

					dispatch({
						type: ADD_MATERIALS_BOM_LOADING,
						payload: false
					})
				}
			} catch (err: any) {
				console.error(err)
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage:
							err.info ||
							err.message ||
							getString('ADD_MULTIPLE_MATERIALS_ERROR')
					}
				})
				dispatch({
					type: ADD_MATERIALS_BOM_LOADING,
					payload: false
				})
			}
		}
	}
}

export const onOrganizationNameChange = (name: string) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		const organizationNameError = validateOrganizationName(name)
		dispatch({
			type: CHANGE_ORGANIZATION_NAME,
			payload: { name, organizationNameError }
		})
	}
}

export const onOrganizationPrivacyChange = (isPrivate: boolean) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: CHANGE_ORGANIZATION_PRIVACY,
			payload: isPrivate
		})
	}
}

export const onOrganizationEditSubmit = (
	organizationId: number,
	name: string,
	isPrivate: boolean,
	setLoading: (loading: boolean) => void,
	onCancel: () => void
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			setLoading(true)

			const response = await updateOrganizationInfo(
				organizationId,
				name,
				isPrivate
			)
			const { organizationDetails = {} } = response?.data

			dispatch({
				type: ORGANIZATION_INFO_UPDATED,
				payload: { organizationDetails }
			})

			setLoading(false)
			onCancel()
		} catch (error: any) {
			console.log(error)
			setLoading(false)
			if (error.internalCode === ORGANIZATION_NAME_ERROR_CODE) {
				dispatch({
					type: UPDATE_ORGANIZATION_INFO_ERROR,
					payload: getString('EDIT_ORGANIZATION_DUPLICATING_NAME_ERROR')
				})
			} else {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage:
							error.message?.message ||
							error.message ||
							CONFIGURATION_CALCULATION_ERROR
					}
				})
			}
		}
	}
}

export const onOrganizationSelect = (organizationId: number) => {
	return (dispatch: any) => {
		setStringItemToLocalStorage(
			SELECTED_ORGANIZATION_LS_KEY,
			organizationId.toString()
		)
		dispatch({
			type: SELECT_ORGANIZATION_TO_CUSTOMIZE,
			payload: { organizationId }
		})
	}
}

export const clearSettingsState = (uploadPage = false) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			if (!uploadPage) {
				removeItemFromLocalStorage(SELECTED_ORGANIZATION_LS_KEY)
			}
			dispatch({ type: RESET_SETTINGS_STATE })
			let userId =
				store.getState().user?.userId || store.getState().user?.userDetails?.id

			const response = await getUserProfileData(userId, false)
			const userProfileData = response.data

			if (userProfileData) {
				isLoggedInSuccess(dispatch, response)
			}
		} catch (error) {
			console.log(error)
		}
	}
}

export const onOrganizationAddClick = (
	name: string,
	isPrivate: boolean,
	duplicateOrganizationId: number,
	setLoading: (loading: boolean) => void,
	setOrganizationNameError: (error: string) => void
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			setLoading(true)
			const response = await addNewOrganization(
				name,
				isPrivate,
				duplicateOrganizationId
			)
			const organization: IOrganization = response?.data?.organization
			setLoading(false)

			if (organization) {
				dispatch({
					type: NEW_ORGANIZATION_ADDED,
					payload: { organization }
				})

				history.push(homePathRoute(ONBOARDING_WIZARD_ROUTE))
			} else {
				throw new Error(response?.data?.message)
			}
		} catch (error: any) {
			console.log(error)
			setLoading(false)
			if (
				error?.internalCode === ORGANIZATION_NAME_ERROR_CODE ||
				error?.message?.internalCode === ORGANIZATION_NAME_ERROR_CODE
			) {
				setOrganizationNameError(
					getString('EDIT_ORGANIZATION_DUPLICATING_NAME_ERROR')
				)
			} else {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage:
							error.message?.message ||
							error.message ||
							CONFIGURATION_CALCULATION_ERROR
					}
				})
			}
		}
	}
}
